import React from "react";
import "semantic-ui-css/semantic.min.css";
import { Button, Modal, Header, Form, Input } from "semantic-ui-react";
import {  updateResumeRoomModal } from "../reducers/session";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class ResumeRoomModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoadSession = this.handleLoadSession.bind(this);
  }

  async handleLoadSession() {
     this.props.updateResumeRoomModal(false);  // Close the modal
    await this.props.initializeProctoringSession();  // Start the session
  }

  render() {
    return (
      <Modal
        open={this.props.showResumeRoomModal}
        closeIcon
        onClose={() => {
          this.handleLoadSession();
        }}
      >
    <Modal.Header>Load Session</Modal.Header>
          <Modal.Content>
            <p>Click the button below to load the session.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.handleLoadSession}>
              Load Session
            </Button>
          </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
        showResumeRoomModal: state.session.showResumeRoomModal,
    }),
    { updateResumeRoomModal }
  )(ResumeRoomModal)
);
