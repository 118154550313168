import React from "react";
import "semantic-ui-css/semantic.min.css";
import {
  Card,
  Button,
  Header,
  Modal,
  Grid,
  Dimmer,
  Message,
  Loader,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { updateZoomDesktop } from "../reducers/session";
import MuteButton from "./MuteButton";
import CallButton from "./CallButton";
import VolumeIndicator from "./VolumeIndicator";
import { withTranslation } from "react-i18next";
import { indexBy } from "underscore";

const poster = require("../images/webcam_loading.jpg");

class WebCamUser extends React.Component {
  constructor(props) {
    super(props);
    this.modalStatus = this.modalStatus.bind(this);
    this.onRefresh = this.onRefresh.bind(this);

    this.state = {
      banModalOpen: false,
      muted: this.props.selected ? "false" : "true",
      bootModalOpen: false,
    };
    this.bootModalStatus = this.bootModalStatus.bind(this);
    this[this.props.user_id + "_webcam"] = React.createRef();
    this[this.props.user_id + "_webcam_audio"] = React.createRef();
    this[this.props.user_id + "_desktop"] = React.createRef();
  }
  componentWillUnmount() {
    if (this.props.webcamVideoStream) {
      var track = this.props.webcamVideoStream.getTracks()[0];
      track.stop();
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.webcamVideoStream != nextProps.webcamVideoStream ||
      this.props.desktopStream != nextProps.desktopStream ||
      this.props.selected != nextProps.selected ||
      "bootModalOpen" in nextState ||
      "banModalOpen" in nextState
    );
  }
  componentDidMount() {
    if (
      this.props.webcamVideoStream &&
      this.props.desktopStream &&
      this.props.webcamAudioStream
    ) {
      this._setMedia();
    }
  }
  _setMedia() {
    // Function to handle autoplay and unmute after user interaction
    const handleUserInteraction = (element) => {
      document.addEventListener("visibilitychange", () => {
        if (!document.hidden) {
          element.muted = false;
          element.play().catch((err) => console.log("Play failed after unmuting", err));
        }
      });
    };
  
    // Setting remote webcam video
    if (this[this.props.user_id + "_webcam"]) {
      let remoteWebcam = this[this.props.user_id + "_webcam"].current;
      if (remoteWebcam) {
        remoteWebcam.srcObject = this.props.webcamVideoStream;
        remoteWebcam
          .play()
          .then(() => {
            console.log("Webcam autoplayed successfully.");
          })
          .catch(() => {
            console.log("Webcam autoplay blocked, waiting for user interaction...");
            handleUserInteraction(remoteWebcam);
          });
      }
    }
  
    // Setting remote webcam audio
    if (this[this.props.user_id + "_webcam_audio"]) {
      let remoteWebcamAudio = this[this.props.user_id + "_webcam_audio"].current;
      if (remoteWebcamAudio) {
        remoteWebcamAudio.srcObject = this.props.webcamAudioStream;  
        remoteWebcamAudio.muted = true;
        remoteWebcamAudio
          .play()
          .then(() => {
            console.log("Audio autoplayed successfully.");
          })
          .catch(() => {
            console.log("Audio autoplay blocked, waiting for user interaction...");
            handleUserInteraction(remoteWebcamAudio);
          });
      }
    }
  
    // Setting remote desktop stream
    if (this[this.props.user_id + "_desktop"]) {
      let remoteDesktop = this[this.props.user_id + "_desktop"].current;
      if (remoteDesktop) {
        remoteDesktop.srcObject = this.props.desktopStream;
        remoteDesktop
          .play()
          .then(() => {
            console.log("Desktop stream autoplayed successfully.");
          })
          .catch(() => {
            console.log("Desktop autoplay blocked, waiting for user interaction...");
            handleUserInteraction(remoteDesktop);
          });
      }
    }
  }
  
  

  
  modalStatus() {
    this.setState({ banModalOpen: !this.state.banModalOpen });
  }
  bootModalStatus() {
    this.setState({ bootModalOpen: !this.state.bootModalOpen });
  }
  onRefresh() {
    this._setMedia();
  }
  render() {
    const {
      onMaximize,
      onMinimize,
      onZoom,
      onBan,
      onSoftBoot,
      onFlag,
      onSwapViews,
      index,
      allUrlModal,
      urlModal,
      socketId,
      username,
      user_id,
      startVideoCall,
      stopVideoCall,
      disconnected,
    } = this.props;

    const selected = this.props.maximizedUser.user_id === user_id;

    const style = selected
      ? {
          width: "27%",
          position: "fixed",
          right: "25%",
          top: "9%",
          zIndex: "3",
        }
      : {
          zIndex: "2",
          width: "17vw",
          marginLeft: "1rem",
          marginBottom: "1rem",
        };
    const id = selected ? "selected-user" : "";

    const buttons = (
      <Card.Content extra className="controls">
        <div className="name-badge name-small">{username}</div>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              {selected ? (
                <Button
                  circular
                  icon="minimize"
                  title={this.props.t("WebCamUser.minimize")}
                  onClick={() => onMinimize(index)}
                />
              ) : null}
              {!selected ? (
                <Button
                  size="mini"
                  title={this.props.t("WebCamUser.maximize")}
                  circular
                  icon="maximize"
                  onClick={() => onMaximize(index)}
                />
              ) : null}
              <Button
                size={selected ? "large" : "mini"}
                circular
                icon="ban"
                title={this.props.t("WebCamUser.ban")}
                onClick={() => this.modalStatus()}
              />
              <Button
                circular
                size={selected ? "large" : "mini"}
                icon="sign out"
                title={this.props.t("WebCamUser.signOut")}
                onClick={() => this.bootModalStatus()}
              />
              <Button
                size={selected ? "large" : "mini"}
                circular
                icon="flag"
                title={this.props.t("WebCamuUser.flag")}
                onClick={() => onFlag(user_id, username)}
              />
              <Button
                size={selected ? "large" : "mini"}
                circular
                icon="linkify"
                title={this.props.t("WebCamUser.singleLink")}
                onClick={() => urlModal(socketId)}
              />
              <Button
                size={selected ? "large" : "mini"}
                circular
                icon="share"
                title={this.props.t("WebCamUser.linkAll")}
                onClick={() => allUrlModal()}
              />
              <MuteButton
                size={selected ? "large" : "mini"}
                user_id={user_id}
                onClick={() => this.setState({ muted: !this.state.muted })}
              />
              {selected ? (
                <CallButton
                  size={selected ? "large" : "mini"}
                  user_id={user_id}
                  startVideoCall={startVideoCall}
                  stopVideoCall={stopVideoCall}
                />
              ) : null}

              <Button
                size={selected ? "large" : "mini"}
                circular
                title="Swap View"
                icon="exchange"
                onClick={() => onSwapViews(user_id)}
              />
              <Button
                size={selected ? "large" : "mini"}
                circular
                icon="refresh"
                title={this.props.t("WebCamUser.refresh")}
                onClick={this.onRefresh}
              />
            </Grid.Column>
            {this.props.webcamAudioStream ? (
              <Grid.Column width={5}>
                <VolumeIndicator
                  webcamStream={this.props.webcamAudioStream}
                  user_id={user_id}
                  width={290}
                />
              </Grid.Column>
            ) : null}
          </Grid.Row>
        </Grid>
      </Card.Content>
    );

    return (
      <Card centered raised fluid id={id} style={style}>
        <Dimmer active={disconnected}>
          <Loader />
          <Message
            className="disconnected-message"
            icon
            hidden={false}
            style={{
              margin: "auto",
              textAlign: "center",
              color: "#0089a8 !important",
              background: "none !important",
              boxShadow: "none !important",
            }}
          >
            <Grid.Row>
              <Grid.Column>
                <Message.Content>
                  <Message.Header>
                    {this.props.t("WebCamUser.userDisconnectedHeader")}
                  </Message.Header>
                  {this.props.t("WebCamUser.userDisconnectedMessage")}
                </Message.Content>
              </Grid.Column>
            </Grid.Row>
          </Message>
        </Dimmer>

        <Modal open={this.state.banModalOpen}>
          <Modal.Header>{this.props.t("WebCamUser.banUser")}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>{username}</Header>
              <p>
                {this.props.t("WebCamUser.confirmBan")} {username}?
              </p>
            </Modal.Description>
            <Button primary onClick={() => onBan(user_id, username)}>
              {this.props.t("general.confirm")}
            </Button>
            <Button negative onClick={() => this.modalStatus()}>
              {this.props.t("general.back")}
            </Button>
          </Modal.Content>
        </Modal>
        <Modal open={this.state.bootModalOpen}>
          <Modal.Header>{this.props.t("WebCamUser.softBanUser")}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>{username}</Header>
              <p>
                {this.props.t("WebCamUser.confirmSoftBoot")} {username}?
              </p>
            </Modal.Description>
            <Button primary onClick={() => onSoftBoot(user_id, username)}>
              {this.props.t("general.confirm")}
            </Button>
            <Button negative onClick={() => this.bootModalStatus()}>
              {this.props.t("general.back")}
            </Button>
          </Modal.Content>
        </Modal>
        <video
          autoPlay
          muted  // Ensures autoplay works in Chrome
          height={""}
          width={""}
          poster={String(poster)}
          ref={this[this.props.user_id + "_webcam"]}
          style={{ maxWidth: "100%" }}
          id={"video_" + this.props.user_id}
        />

        <audio
          autoPlay
          muted  // Ensures autoplay works for audio
          id={this.props.user_id + "_audio"}
          ref={this[this.props.user_id + "_webcam_audio"]}
        />

        <video
          hidden={!selected}
          autoPlay
          muted  // Ensures autoplay works for the desktop stream
          onClick={() => onZoom(this.props.user_id)}
          height={""}
          width={""}
          poster={String(poster)}
          ref={this[this.props.user_id + "_desktop"]}
          id={"desktop_" + this.props.user_id}
          style={{ maxWidth: "100%" }}
        />

        <Card.Content extra>
          <Grid columns="equal">
            <Grid.Row>
              {buttons}
              <Grid.Column width={5}></Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      zoomDesktop: state.session.zoomDesktop,
      maximizedUser: state.session.maximizedUser,
    }),
    { updateZoomDesktop }
  )(WebCamUser)
);
